import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyC1r1yEU7LNspeibybeBZDqPTRWa39H9A8",
  authDomain: "terramattertestdemo.firebaseapp.com",
  projectId: "terramattertestdemo",
  storageBucket: "terramattertestdemo.firebasestorage.app",
  messagingSenderId: "689050117388",
  appId: "1:689050117388:web:d686b260d88d20eb3ad529",
  measurementId: "G-1ZBS0E92ZV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const storage = getStorage(app);
// const storageRef = ref(storage, 'images');
const storageBox = {
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject
};


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export const firebaseData = { db, analytics, storageBox }

export default firebaseData;