import React from 'react';
import { Input, Segment, Grid, TextArea, Form, Button, Header } from 'semantic-ui-react';
import useSendEmail from './hooks/useSendEmail';

const ContactForm = () => {
  const { sendEmail, onChange, form } = useSendEmail();
  return (
    <Segment inverted style={{padding: '4em 0', margin: 0}}>
      <Grid style={{padding: '4em 0'}} container stackable verticalAlign='middle' centered>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form>
              <Header as='h3' style={{ fontSize: '2em' }} inverted>Contact Us</Header>
              <Input name="email" value={form.email} fluid icon='mail' iconPosition='left' placeholder='Email' style={{marginBottom: '2em'}} onChange={onChange} />
              <Input name="subject" value={form.subject} fluid placeholder='Subject' style={{marginBottom: '2em'}} onChange={onChange} />
              <TextArea name="message" value={form.message} rows={6} fluid placeholder='Tell us more' style={{marginBottom: '2em'}} onChange={onChange} />
              <Button type='submit' disabled={!form.email || !form.subject || !form.message} primary onClick={sendEmail}>Send</Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default ContactForm;