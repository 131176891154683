import { useState } from 'react';
import { addDoc, collection } from "firebase/firestore";
import firebaseData from '../../../../web/Firebase/firebase';

const useSendEmail = () => {
  const [form, setForm] = useState({
    email: '',
    subject: '',
    message: ''
  });

  const onChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const sendEmail = async () => {
    const data = {
      ...form,
      id: Date.now(),
    };

    try {
      await addDoc(collection(firebaseData.db, "Emails"), data);
      window.alert('Email sent successfully');
    } catch (err) {
      console.log(err);
    }
  };

  return {
    form,
    onChange,
    sendEmail,
  }
}

export default useSendEmail; 